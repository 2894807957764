import Vue from 'vue'
import Component from 'vue-class-component'
import {
    UserDetail,
} from "@/request/manage";
import { IsShowQuanxian } from "@/utils/base";
@Component({})
export class quanxianMixin extends Vue {
    get quanxianData() {
        return this.$store.state.quanxianData;
    }
    /**
     * @description 权限处理
     */
    getIsShowQuanxian(tool: any) {
        return IsShowQuanxian(tool, this.quanxianData)
    }
    getUser() {
        const params: any = {
            params: {
                user_id: this.$store.state.user.user_id,
            },
        };
        UserDetail(this, params).then((res: any) => {
            this.$store.commit('updateQuanxianData', res);
            this.$forceUpdate();
        });
    }
    mounted() {
        this.getUser();
    }
}