























































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import { clearFilter } from "@/utils/base";
import { EditPwd } from "@/request/manage";
import { GetKuList } from "@/request/common";
import { GetBiaozhuOption } from "@/request/mark";
@Component({})
export default class Name extends mixins(quanxianMixin) {
  private window: any = window;
  private ifShowPassword: any = false;
  private list: any = [];
  private newPassword: string = "";
  private renewPassword: string = "";
  private oldPassword: string = "";
  private navs: any = {
    知识校验: [
      {
        name: "实体校验",
        label: "实体校验",
        path: "/main/check/bentijiaoyan/list",
      },
      {
        name: "原文校验",
        label: "原文校验",
        path: "/main/check/yuanwenjiaoyan/list",
      },
      {
        name: "校验管理",
        label: "校验管理",
        path: "/main/check/jiaoyanguanli/list",
      },
      {
        name: "属性来源",
        label: "属性来源",
        path: "/main/check/shuxinglaiyuan/list",
      },
      {
        name: "schema",
        label: "Schema管理",
        path: "/main/mark/schema/folder",
      },
    ],
    知识标注: [
      {
        name: "任务管理",
        label: "任务管理",
        path: "/main/mark/guanli/list",
      },
      {
        name: "任务标注",
        label: "任务标注",
        path: "/main/mark/biaozhu/list",
      },
      {
        name: "任务审核",
        label: "任务审核",
        path: "/main/mark/shenhe/list",
      },
      {
        name: "标注结果库",
        label: "标注结果库",
        path: "/main/mark/jieguo/list",
      },
      {
        name: "进度统计",
        label: "进度统计",
        path: "/main/mark/tongji/folder",
      },
    ],
    系统管理: [
      {
        name: "用户管理",
        label: "用户管理",
        path: "/main/manage/zhanghu/list",
      },
      {
        name: "角色管理",
        label: "角色管理",
        path: "/main/manage/juese/list",
      },
    ],
  };
  private get user() {
    return this.$store.state.user;
  }
  private get Allku() {
    return this.$store.state.kuList;
  }
  private get kuList() {
    const num: any = 10;
    let data: any = [];
    if (this.$store.state.kuList.length > num) {
      this.$store.state.kuList.forEach((items: any, index: any) => {
        if ((index + 1) % num === 1) {
          data.push([items]);
        } else {
          data[data.length - 1].push(items);
        }
      });
    } else {
      data = [this.$store.state.kuList];
    }
    return data;
  }
  private getPartQuanxian(val: any) {
    let result: any = false;
    if (val == "中医药知识库") {
      this.Allku.forEach((ele: any) => {
        if (this.getIsShowQuanxian(ele.table_name + "库搜索")) {
          result = true;
        }
      });
    } else if (val == "实体库") {
    } else {
      this.navs[val].forEach((ele: any) => {
        if (this.getIsShowQuanxian(ele.name)) {
          result = true;
        }
      });
    }
    return result;
  }
  private goDefault() {
    clearFilter();
    this.$router.push("/main/home/show");
  }
  private goZhishiku(val: any) {
    clearFilter();
    val.table_name = val.label;
    this.$store.commit("updateCurrentKu", val.table_name);
    const source: any = val.source || "中医药知识库";
    this.$router.push(
      "/main/storehouse/folder?source=" + source + "&&t=" + new Date().getTime()
    );
  }
  private goRouter(path: any) {
    clearFilter();
    this.$router.push(path);
  }
  /**
   * @description 切换知识库
   */
  private changeModel(val: any) {
    this.$store.commit("updateModel", val);
    location.reload();
  }
  /**
   * @description 点击切换账号
   */
  private handleCommand(command: any) {
    if (command === "修改密码") {
      this.oldPassword = "";
      this.newPassword = "";
      this.renewPassword = "";
      this.ifShowPassword = true;
    } else if (command === "退出登录") {
      localStorage.removeItem("token");
      this.$router.replace("/access/login");
    }
  }
  /**
   * @description 跳转个人中心
   */
  private goAccountManage() {
    localStorage.setItem(
      "beforeAccountSource",
      JSON.stringify({
        path: this.$route.path,
        query: this.$route.query,
      })
    );
    this.$router.push({
      path: "/main/account/safe/safe-folder",
    });
  }
  /**
   * @description 确定修改密码
   */
  private edit() {
    const reg = /^(?=.*[0-9])(?=.*[a-zA-Z])(.{8,})$/;
    if (!this.oldPassword) {
      this.$message.warning("当前密码不能为空");
      return;
    }
    if (!this.newPassword) {
      this.$message.warning("新密码不能为空");
      return;
    }
    if (!this.renewPassword) {
      this.$message.warning("重复新密码不能为空");
      return;
    }
    if (!reg.test(this.oldPassword)) {
      this.$message.warning(
        "当前密码长度必须8位及以上，且需同时包含字母和数字"
      );
      return;
    }
    if (!reg.test(this.newPassword)) {
      this.$message.warning("新密码长度必须8位及以上，且需同时包含字母和数字");
      return;
    }
    if (this.newPassword !== this.renewPassword) {
      this.$message.error("两次输入的密码不一致，请重新输入");
      // this.newPassword = "";
      // this.renewPassword = "";
      return;
    }
    const params = {
      user_id: this.user.user_id,
      old_password: this.oldPassword,
      new_password: this.newPassword,
    };
    EditPwd(this, params).then(() => {
      this.$message.success("修改成功");
      this.newPassword = "";
      this.renewPassword = "";
      this.ifShowPassword = false;
      localStorage.removeItem("token");
      this.$router.push("/access/login");
    });
  }
  private getOption() {
    const params: any = {
      kind: "语义类型",
    };
    GetBiaozhuOption(this, params).then((res: any) => {
      this.list = res;
    });
  }
  mounted() {
    // 自动登录
    const user: any = JSON.parse(localStorage.getItem("user") || "");
    if (!this.$store.state.user.user_id) {
      this.$store.commit("updateUser", user);
    }
    // 获取知识库列表
    GetKuList(this).then((res: any) => {
      this.$store.commit("updateKuList", res);
    });
    // this.getOption();
  }
}
