import axios, { resHandle } from "@/plugins/axios";

/**
 * @description 获取分类
 * @param vCom Vue组件
 */
const GetCategory = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/knowledge/task/category", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取标注任务列表
 * @param vCom Vue组件
 */
const GetBiaozhuyuan = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/user/markers", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取标注任务列表
 * @param vCom Vue组件
 */
const GetRenwuList = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/annotator/tasks", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 删除任务
 * @param vCom Vue组件
 */
const DeleteRenwu = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.delete("api/annotator/task", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 搜索书籍
 * @param vCom Vue组件
 */
const SearchBook = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/knowledge/book", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取书籍章节
 * @param vCom Vue组件
 */
const GetBookZhangjie = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/knowledge/book", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取任务选项
 * @param vCom Vue组件
 */
const GetRenwuOption = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/annotator/task", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 新建标注任务
 * @param vCom Vue组件
 */
const AddRenwu = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/annotator/task", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 查看标注进度
 * @param vCom Vue组件
 */
const GetJindu = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/annotator/process", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 分配标注员
 * @param vCom Vue组件
 */
const Fenpei = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.put("api/annotator/task", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 查看标注任务详情页
 * @param vCom Vue组件
 */
const RenwuDetail = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/annotator/tasks", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 编辑标注任务分类
 * @param vCom Vue组件
 */
const EditFenlei = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/knowledge/task/category", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取语义类型标注的选项
 * @param vCom Vue组件
 */
const GetBiaozhuOption = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/annotator/type", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 保存标注
 * @param vCom Vue组件
 */
const SaveBiaozhu = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/annotator/save", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 提交审核，审核通过，打回修改
 * @param vCom Vue组件
 */
const TijiaoBiaozhu = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/annotator/save", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取进度统计概览
 * @param vCom Vue组件
 */
const GetOverview = (vCom: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/annotator/process/overview").then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取进度统计状态左边
 * @param vCom Vue组件
 */
const GetRenwuZhuangtaiLeft = (vCom: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/annotator/process/category/tasks").then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取进度统计状态右边
 * @param vCom Vue组件
 */
const GetRenwuZhuangtaiRight = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/annotator/process/category/tasks", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取任务分配数量
 * @param vCom Vue组件
 */
const GetFenpeiNum = (vCom: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/annotator/process/user/tasks").then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 默认选中的语义类型
 * @param vCom Vue组件
 */
const GetTuijianType = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/annotator/entity", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取关联的概念实体
 * @param vCom Vue组件
 */
const GetEntity = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/annotator/entity", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取实体属性
 * @param vCom Vue组件
 */
const GetShuxing = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/knowledge/attributes", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};

/**
 * @description 获取所有可选的语义类型，可搜索
 * @param vCom Vue组件
 */
const GetSechemaList = (vCom: any,params:any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/knowledge/sechema_list",params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            }).catch((err) => {
                reject(err);
            });
        }).catch((err) => {
            reject(err);
        });
    });
};
export {
    GetCategory, GetBiaozhuyuan, GetRenwuList, DeleteRenwu, SearchBook, GetBookZhangjie,
    GetRenwuOption, AddRenwu, GetJindu, Fenpei, RenwuDetail, EditFenlei, GetBiaozhuOption,
    SaveBiaozhu, TijiaoBiaozhu, GetOverview, GetRenwuZhuangtaiLeft, GetRenwuZhuangtaiRight,
    GetFenpeiNum, GetTuijianType, GetEntity, GetShuxing, GetSechemaList
}